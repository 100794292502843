
.calendar-card {
    width: 100%!important;
}

.site-calendar-customize-header-wrapper {
    /*width: 200px;*/
    /*border: 1px solid #f0f0f0;*/
    /*border-radius: 2px;*/
    /*font-size: smaller;*/
}
.ant-picker-calendar {
    font-size: 10px!important;
}