.order-button {
    height: 50px;
    width: 80%;
    margin: auto 10%;
    border-radius: 32px;
}

.waiter-button {
    margin: 2% 10%;
}

.waiter-paid-button {
    margin: 10px 10%;
}

.scrollable-container {
    height: 400px;
    overflow-y: scroll;
    padding-right: 10px;
}

.new-order-item-button {
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    border-color: #d9d9d9;
    background: #fff;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
}

.active-category-button,
.active-subcategory-button,
.active-item-button
{
    background-color: #1890ff;
}

/* {*/
/*    background-color: #1890ff;*/
/*}*/
/* {*/
/*    background-color: #1890ff;*/
/*}*/

.checkbox-button {
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    padding: 4px 15px;
    border-color: #d9d9d9;
    background: #fff;
    width: 100%;
    height: 100%;
}
.ant-checkbox-wrapper-checked.checkbox-button {
    background-color: #1890ff;
}

.ant-checkbox-group {
    display: block;
}
.item-served {
    background-color: greenyellow!important;
}

.item-sent {
    background-color: yellow!important;
}

.item-new {
    background-color: aqua!important;
}

td.ant-table-column-sort{
    background: inherit;

}
.ant-table-cell-row-hover {
    background: rgba(250, 250, 250, 0.7)!important;
}

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
    html, body {
        margin: 0px !important;
        padding: 0 !important;
        overflow: hidden;
    }
    .print-divider {
        border-top-color: rgba(0,0,0,0.5)!important;
    }
    .print-sum {
        text-align: right;
        margin-top: 0px;
        margin-bottom: 0.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        display: block;
    }
}


.custom-form-item-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
    text-align: left; /* or center, right, etc. */
  }

  
@media screen and (max-width: 1200px) {
    .custom-form-item-label {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
      min-height: 40px; /* adjust the value as needed */
      line-height: 20px; /* adjust the value as needed */
    }
  }
  
